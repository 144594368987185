<template>
  <b-card>
    <table-render 
        id="listSumaryLogs" 
        v-if="!loading.first"
        class="mt-2" 
        :schema="schema" 
        :rows="rows">
      </table-render>
      <div v-if="loading.first" class="table-render-skeleton">
        <b-skeleton-table
          :rows="10"
          :columns="schema.length || 10"
          :table-props="{ }"/>
      </div>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseServices from '@/store/services/index'
export default {
  data() {
    return {
      schema: [],
      rows: [],
      loading: {
        first: true
      },
      baseService: new BaseServices(this)
    } 
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  mounted () {
    this.getAllInitialData()
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.setInitialData()
    }
  },
  methods: {
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData () {
      this.schema = [
        { label: 'ID - Manifiesto', key: 'delivery_label_print_id' },
        { label: 'Email', key: 'email' },
        { label: 'Fecha', key: 'created_at' },
        { label: 'Tarea cometida', key: 'committed_task' }
      ]
      this.getSummaryLogs() 
    },
    getSummaryLogs () {
      this.loading.first = true
      this.baseService.callService('getSummaryLogs', null, { summary_id: this.$route.params.id })
        .then(res => {
          this.rows = res.logs
        })
        .catch(error => {
          this.$alert(this.$t('Ocurrió un problema al consultar los logs', {code: error}))
        })
        .finally(() => {
          this.loading.first = false
        })
    }
  }
}
</script>

<style>

</style>